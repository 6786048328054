import React from 'react'

class SidebarLeft extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <aside
        id="sidebar"
        role="complementary"
        itemScope="itemscope"
        itemType="http://schema.org/WPSideBar"
        style={{
          height: `100%`,
          textAlign: `center`,
        }}
      >
        Left sidebar
      </aside>
    )
  }
}

export default SidebarLeft
