import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'

import { Container, Row, Col } from 'reactstrap'
import Sidebar from '../components/sidebar'
import SidebarLeft from '../components/sidebar-left'

import { css } from 'react-emotion'

class BlogPageTemplate extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = page.excerpt
    const { previous, next } = this.props.pageContext

    const pageStyles = css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 30px 0;
      }

      p {
        font-size: 1.2em;
      }

      img {
        display: block;
        margin: 5px auto 5px auto;
        max-width: 100%;
        width: auto;
        height: auto;
      }

      strong {
        font-weight: 700 !important;
      }

      ul {
        margin-left: 20px;
      }

      li {
        /*font-family: Georgia, Cambria, "Times New Roman", Times, serif;*/
        /*font-weight: 700;*/
        font-size: 1.1em;
        line-height: 1.8em;
      }
    `

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${page.frontmatter.title} | ${siteTitle}`}
        />

        <Container fluid={true} style={{}}>
          <article
            itemScope="itemscope"
            itemType="http://schema.org/BlogPosting"
            itemProp="blogPost"
            style={{
              backgroundColor: `#fff`,
              padding: `35px`,
            }}
          >
            <Row>
              <header
                itemScope="itemscope"
                itemType="http://schema.org/WPHeader"
                style={{
                  backgroundColor: `#45c5f7`,
                  color: `#fff`,
                  width: `100%`,
                  padding: `40px 40px 20px 40px`,
                  marginBottom: `30px`,
                  textAlign: `center`,
                  textTransform: `uppercase`,
                }}
              >
                <h1>{page.frontmatter.title}</h1>

                <p
                  style={{
                    textTransform: `uppercase`,
                    marginBottom: `10px`,
                  }}
                >
                  <meta
                    itemProp="datePublished"
                    content={page.frontmatter.date_unformatted}
                  />
                  <small>
                    <time
                      itemProp="dateModified"
                      dateTime={page.frontmatter.date_unformatted}
                    />
                  </small>
                </p>
              </header>
            </Row>

            <Row>
              <Col xs="12" md="9" lg="9">
                <div
                  className={pageStyles}
                  dangerouslySetInnerHTML={{ __html: page.html }}
                />
              </Col>
              <Col md="3" lg="3" className="d-none d-md-block">
                <Sidebar />
              </Col>
            </Row>
          </article>
        </Container>
      </Layout>
    )
  }
}

export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { url: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date_unformatted: date
        date_formatted: date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
